import { bindable, customElement } from 'aurelia-framework';
import { BaseComponent } from '../shared/components/base.component';
import { DistrictService } from '../shared/services/district';
import { AddressService } from '../shared/services/address';
import { EZRoutingService } from '../shared/services/ezr';

export class SearchAddressFmb extends BaseComponent {
	static inject() {
		return [ Element, EZRoutingService, DistrictService, AddressService ];
	}

	constructor(element, ezr, ds, addr, ...rest) {
		super(...rest);
		this.element = element;
		this.ezr = ezr;
		this.ds = ds;
		this.addr = addr;
		this.data = {
			client: window.client,
			address: "",
			schoolId: 0,
			grade: 0,
			sameBus: false,
			sped: false,
			tprogram: 0,
		};
	}

	attached() {
		$("search-address-fmb input")[0].addEventListener('keypress', e=> {
			var key = e.which || e.keyCode;
			if (key === 13) this.search();
		});
		$("input").focus();
		$('.tooltipped').tooltip();

		this.schools = this.ezr.schools;
		this.grades = this.ezr.grades;
		this.settings = this.ezr.settings;
		this.transportPrograms = this.ezr.transportPrograms || [];
	}

	@bindable streetAddress = "";

	streetAddressChanged() {
		this.data.address = this.streetAddress;
		if (this.suggestHandler)
			clearTimeout(this.suggestHandler);
		this.suggestHandler = setTimeout(() => {
			this.generateList();
		}, 300);
	}

	async generateList() {
		if (!this.data.address || this.data.address.length < 2) return;
		var point = this.map.mapObj.getCenter();
		this.items = await this.ezr.suggestAddress(this.data.address, point, window.client);
	}

	itemSelected(event) {
		this.streetAddress = event.detail.street_line + ", " + event.detail.city + " " + event.detail.state;
		this.data.address = this.streetAddress;
		this.search();
	}

	startPin() {
		this.map.clickLocation(async p => {
			var r = await this.addr.findPlace(p);
			this.streetAddress = r.address + ", " + r.city + " " + r.state;
			this.data.address = this.streetAddress;
			this.data.lat = p.lat;
			this.data.lng = p.lng;
			this.centerOnMap(p);
		});
	}

	async search() {
		if (!this.streetAddress.trim()) return;
		var point = await this.addr.geocode(this.data);
		this.data.lat = point.lat;
		this.data.lng = point.lng;
		this.ea.publish("addressEntered");
		this.centerOnMap(this.data); // must be done after `addressEntered` published
	}

	async centerOnMap(point) {
		this.map.mapObj.setZoom(15);
		var offset = 0;
		var width = this.map.canvas.offsetWidth;
		var east = this.map.mapObj.getBounds().getEast();
		var west = this.map.mapObj.getBounds().getWest();
		offset = (west - east) * (400 / width / 2);
		this.map.centerOnMap({ lat: point.lat, lng: point.lng + offset }, 15);
		this.map.highlight(point);
	}

	async findMyBus() {
		await this.search();
		var res = null;
		if (this.streetAddress && this.data.lng && this.data.lat && this.data.schoolId) {
			this.ea.publish("setReady", false);
			res = await this.ezr.findMyBus(this.data);
			this.ea.publish("setReady", true);
		} else if (this.streetAddress && this.data.lng && this.data.lat && this.ezr.district) {
			var point = turf.point([this.data.lng, this.data.lat]);
			var polygons = this.map.polygon(this.ezr.district.geometry.coordinates);
			if (!Array.isArray(polygons)) polygons = [polygons];
			var insideDistrict = false;
			for (var p of polygons)
				if (turf.inside(point, p))
					insideDistrict = true;
			res = { insideDistrict, checkInsideOnly: true };
		}
		setImmediate(() => {
			var e = document.createEvent("CustomEvent");
			e.initCustomEvent('result', true, true, res);
			this.element.dispatchEvent(e);
		});
	}

	reset() {
		this.data.schoolId = 0;
		this.streetAddress = "";
		setImmediate(() => {
			var e = document.createEvent("CustomEvent");
			e.initCustomEvent('clear', true, true, null);
			this.element.dispatchEvent(e);
		});
	}
}