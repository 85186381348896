import { bindable, bindingMode, customElement } from 'aurelia-framework';
import { BaseComponent } from './base.component';

export class AutoComplete extends BaseComponent {
	static inject() {
		return [ Element ];
	}

	constructor(element, ...rest) {
		super(...rest);
		this.element = element;
		this.id = "ac-" + this.Util.uuid();
		this.visible = true;
	}

	attached() {
		$("#" + this.id).focus(() => this.visible = true);
		$("#" + this.id).blur(() => {
			// very tricky here, clicking the dropdown also fires blur event
			$(window).one('mouseup', () => this.visible = false);
		});
	}

	@bindable placeholder = "";
	@bindable items = [];
	@bindable({defaultBindingMode: bindingMode.twoWay}) value = "";

	inputHasChanged() {
		var e = document.createEvent("CustomEvent");
		e.initCustomEvent('change', true, true, null);
		this.element.dispatchEvent(e);
	}

	select(item) {
		var e = document.createEvent("CustomEvent");
		e.initCustomEvent('selected', true, true, item);
		this.element.dispatchEvent(e);
		this.visible = false;
	}
}