import { bindable, customElement } from 'aurelia-framework';
import { BaseComponent } from '../shared/components/base.component';
import { DistrictService } from '../shared/services/district';
import { AddressService } from '../shared/services/address';
import { EZRoutingService } from '../shared/services/ezr';

export class SearchAddressFms extends BaseComponent {
	static inject() {
		return [ Element, EZRoutingService, DistrictService, AddressService ];
	}

	constructor(element, ezr, ds, addr, ...rest) {
		super(...rest);
		this.element = element;
		this.ezr = ezr;
		this.ds = ds;
		this.addr = addr;
		this.data = {
			address: "",
			grade: 0
		};
	}

	attached() {
		$("search-address-fms input")[0].addEventListener('keypress', e=> {
			var key = e.which || e.keyCode;
			if (key === 13) this.search();
		});
		// $('select').material_select();
		$("input").focus();
		$('.tooltipped').tooltip();
	}

	@bindable streetAddress = "";

	async addressChanged() {
	}

	streetAddressChanged() {
		this.data.address = this.streetAddress;

		if (this.suggestHandler)
			clearTimeout(this.suggestHandler);
		this.suggestHandler = setTimeout(() => {
			this.generateList();
		}, 300);
	}

	async generateList() {
		if (!this.data.address || this.data.address.length < 2) return;
		var point = this.map.mapObj.getCenter();
		this.items = await this.ezr.suggestAddress(this.data.address, point, window.client);
	}

	itemSelected(event) {
		this.streetAddress = event.detail.street_line + ", " + event.detail.city + " " + event.detail.state;
		this.data.address = this.streetAddress;
		this.search();
	}

	gradeSelected() {
		// this.ea.publish("gradeSelected", this.data.grade);
		this.search();
	}

	startPin() {
		this.map.clickLocation(async p => {
			var r = await this.addr.findPlace(p);
			this.streetAddress = r.address + ", " + r.city + " " + r.state;
			await this.searchResults(p);
		});
	}

	async search() {
		if (!this.streetAddress.trim()) return;
		var point = await this.addr.geocode(this.data);
		if (point)
			await this.searchResults(point);
	}

	async searchResults(point) {
		var district = await this.ds.getDistrictByPoint({ point: point, grade: this.data.grade });
			
		var schools = await this.ds.findNearestSchools({
			point: point,
			district: district.code,
			grade: this.data.grade
		});
		
		schools.sort((s1, s2) => s1.distanceToPoint - s2.distanceToPoint);
		schools = schools.filter(s => s.isMine).concat(schools.filter(s => !s.isMine));
		
		setImmediate(() => {
			var e = document.createEvent("CustomEvent");
			e.initCustomEvent('result', true, true, {
				point: point,
				district: district,
				schools: schools
			});
			this.element.dispatchEvent(e);
		});
	}

	reset() {
		this.data.schoolId = 0;
		this.streetAddress = "";
		setImmediate(() => {
			var e = document.createEvent("CustomEvent");
			e.initCustomEvent('clear', true, true, null);
			this.element.dispatchEvent(e);
		});
	}
}