import 'babel-polyfill';
import * as Bluebird from 'bluebird';

Bluebird.config({ warnings: { wForgottenReturn: false } });

export async function configure(aurelia) {
  aurelia.use.standardConfiguration()
  await aurelia.start();
  await aurelia.setRoot(PLATFORM.moduleName('home/app'));
}
