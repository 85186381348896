import {HttpClient} from 'aurelia-http-client';

export class Api {
	constructor() {
		this.client = new HttpClient();
	}

	async get(url) {
		try {
			var r = await this.client.get(url);
			if (r.headers.get("Encrypted") == "true")
				return this._parse(r.response);
			else
				return JSON.parse(r.response);
		} catch(e) {
			this._handler(e);
		}
	};

	async post(url, data) {
		try {
			var d = this.prepare(data);
			var r = await this.client.post(url, d);
			if (r.headers.get("Encrypted") == "true")
				return this._parse(r.response);
			else
				return JSON.parse(r.response);
		} catch(e) {
			this._handler(e);
		}
	}

	async put(url, data) {
		try {
			var d = this.prepare(data);
			var r = await this.client.put(url, d);
			if (r.headers.get("Encrypted") == "true")
				return this._parse(r.response);
			else
				return JSON.parse(r.response);
		} catch(e) {
			this._handler(e);
		}
	}

	async delete(url) {
		try {
			await this.client.delete(url);
		} catch(e) {
			this._handler(e);
		}
	}       

	///////////////

	prepare(obj) {
		var str = JSON.stringify(obj || {});
		var code = btoa(Math.random()).substr(8,4);
		var output = code + CryptoJS.AES.encrypt(str, code).toString();
		return { data: output };
	}

	_parse(r) {
		var key = r.substr(0, 4);
		r = r.substr(4);
		var bytes = CryptoJS.AES.decrypt(r, key);
		r = bytes.toString(CryptoJS.enc.Utf8);
		r = JSON.parse(r);
		return r;
	}

	_handler(e) {
		if (e && e.data && e.data.errcode == "613CA956") {
			location.reload();
		} else if (e.response && typeof e.response == "string") {
			var r = JSON.parse(e.response);
			if (r && r.errcode == "613CA956")
				location.reload();
			else
				throw JSON.parse(e.response);
		} else {
			throw e;
		}
	}
}