export class Util {

	/*Functions:
		distinct
		sortBy
		sortAlphaNum
		timeToInt
		intToTime
		parseDate
		getDay
		parseIcal
		icalOverlapsWithSemester
		getTimezone
		dayIsWithinRange
		dateTimeText
		timeText
		dateText
		timeSpanText
		compareTime
		dayOfRule
		formatNum
		formatDistance
		isInteger
		isNumber
		weekdaysToInt
		intToWeekdays
		getDirectionText
		getDirectionAngle
		findArray
		fromSimpleList
		alert
		confirm
		prompt
		uuid
		getCompareString
		randomString
		compareString
		randomColor
		soundex
		lineDistance
		convertDistance
		levDist
		csv2array
		weekdayNames
		monthNames
		sameAddress
		clone
		post
		deepCompare
		clearMouseEvents
		download
	*/

	/**
	 * Find unique values of an array
	 * @param  {array}
	 */
	static distinct(array) {
		var check = {};
		var result = [];
		for (var e of array) {
			var key = JSON.stringify(e);
			if (!check[key]) {
				result.push(e);
				check[key] = true;
			}
		}
		return result;
	}

	/**
	 * Sort array
	 * @param {Array} array 
	 * @param {String} fieldName 
	 */
	static sortBy(array, fieldName) {
		if (array && array.length) {
			var type = typeof array[0][fieldName];
			if (type == "string")
				array.sort((e1, e2) => e1[fieldName].localeCompare(e2[fieldName]));
			else if (type == "number")
				array.sort((e1, e2) => e1[fieldName] - e2[fieldName]);
			return array;
		} else {
			return array;
		}
	}

	/**
	 * Sort array with alphanumeric characters
	 * https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
	 * @param {String} a
	 * @param {String} b
	 */
	static sortAlphaNum(a, b) {
		return (a || "").toString().localeCompare((b || "").toString(), 'en', { numeric: true, ignorePunctuation: true });
	}

	/**
	 * Convert date object or string to integer
	 * @param {object|string} value 
	 */
	static timeToInt(value) {
		if (value === null) return null;
		var t = new Date(value);
		var r = t.getHours() * 3600 + t.getMinutes() * 60 + t.getSeconds();
		return r;
	}

	/**
	 * Convert integer to time
	 * @param {number} value 
	 */
	static intToTime(value) {
		if (value === null) return null;
		var h = Math.floor(value / 3600);
		var m = Math.floor((value % 3600) / 60);
		var s = value % 60;
		var t = new Date(1970, 0, 1, h, m, s);
		return t;
	}

	/**
	 * Parse date string to local time
	 * @param {string} str 
	 */
	static parseDate(str) {
		var t, m;
		if (typeof str == "string") {
			if (m = str.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d+)Z$/)) {
				return new Date(str);
			} else if (m = str.match(/^(\d{4})-(\d{1,2})-(\d{1,2})/)) {
				return new Date(Number(m[1]), Number(m[2]) - 1, Number(m[3]));
			} else if (m = str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})/)) {
				return new Date(Number(m[3]), Number(m[1]) - 1, Number(m[2]));
			} else if (m = str.match(/^(\d{1,2})-([a-z]+)-(\d{2,4})$/)) {
				var year = Number(m[3]);
				if (year < 100) {
					if (year + 2000 < new Date().getFullYear())
						year += 2000;
					else
						year += 1900;
				}
				return new Date(year, moment().month(m[2]).month(), Number(m[1]));
			} else {
				var b = str.split(/\D/);
				if (b.length >= 6 && b[0] && b[1] && b[2] && b[3] && b[4] && b[5])
					return new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5]));
				else
					return null;
			}
		} else if (str && typeof str == "object" && str.constructor.name == "Date") {
			return new Date(str); // create a copy
		} else {
			return null;
		}
	}

	/**
	 * Get number of days since 1/1/2000
	 * @param {date} date 
	 */
	static getDay(date) {
		var t0 = Date.UTC(2000, 0, 1);
		if (typeof date == "string") {
			var m = date.match(/^(\d{4})-(\d+)-(\d+)$/);
			if (m) {
				var t = Date.UTC(Number(m[1]), Number(m[2]) - 1, Number(m[3]));
				return Math.round((t - t0) / 86400000);
			}
	
			m = date.match(/^(\d+)\/(\d+)\/(\d{4})$/);
			if (m) {
				var t = Date.UTC(Number(m[3]), Number(m[1]) - 1, Number(m[2]));
				return Math.round((t - t0) / 86400000);
			}
		} else if (typeof date == "object" && date.constructor.name == "Date") {
			var t = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
			return Math.round((t - t0) / 86400000);
		} else {
			return null;
		}
	}

	/**
	 * Process ical object and fill in RRule and text
	 * @param {object} ical 
	 */
	static parseIcal(ical) {

		var t0 = Util.parseDate(ical.dtstart);
		var t1 = ical.until ? Util.parseDate(ical.until) : null;
		if (ical.pattern === "CUSTOM") {
			ical.text = ical.dates.length + (ical.dates.length > 1 ? " days " : " day ") + "selected";
			ical.fullText = ical.text;
		} else {
			ical.rule = rrulestr(ical.pattern);
			var text = ical.rule.toText();
			
			if (text.toLowerCase().replace(/\s/g, '').indexOf("monday,tuesday,wednesday,thursday,friday,saturday,sunday") >= 0)
				text = "Seven days a week";

			ical.rule.origOptions.dtstart = t0;
			if (ical.until)
				ical.rule.origOptions.until = t1;
			ical.rule = rrulestr(ical.rule.toString());

			if (ical.dtstart == ical.until) {
				ical.text = moment(t1).format("LL") + " only";
				ical.fullText = ical.text;
				ical.oneDayOnly = true;
			} else {
				ical.text = text[0].toUpperCase() + text.slice(1);
				ical.fullText = ical.text + ` (${Util.dateText(ical.dtstart)} ~ ${Util.dateText(ical.until)})`;
			}
		}

		if (!ical.between)
			ical.between = (before, after, dateFormat, includeBothEnds) => {
				dateFormat = dateFormat || "YYYY-MM-DD";
				includeBothEnds = includeBothEnds !== false ? true : false;
				if (ical.pattern === "CUSTOM")
					if (includeBothEnds)
						return (ical.dates || []).filter(d => moment(d) >= moment(before) && moment(d) <= moment(after)).map(t => moment(t).format(dateFormat));
					else
						return (ical.dates || []).filter(d => moment(d) > moment(before) && moment(d) < moment(after)).map(t => moment(t).format(dateFormat));
				else 
					return ical.rule.between(Util.parseDate(before), Util.parseDate(after), includeBothEnds).map(t => moment(t).format(dateFormat));
			}
		
		return ical;
	}

	/**
	 * compare two icals, 0 - ical1 DC ical2, 1 - ical1 PO ical2, 2 - ical1 PP ical2, 3 - ical1 PPI ical2, 4 - ical1 EQ ical2
	 * @param {Ical} ical1 
	 * @param {Ical} ical2 
	 */
	static compareIcal(ical1, ical2) {
		var list1 = ical1.all();
		var list2 = ical2.all();

		var intersect = _.intersection(list1, list2);
		if (!intersect.length)
			return 0
		else if (intersect.length < list1.length && intersect.length < list2.length)
			return 1
		else if (intersect.length == list1.length && intersect.length != list2.length)
			return 2
		else if (intersect.length != list1.length && intersect.length == list2.length)
			return 3
		else if (intersect.length == list1.length && intersect.length == list2.length)
			return 4
	}

	/**
	 * Check if a icalendar is overlapping with a semester
	 * @param {Object} ical 
	 * @param {Object} semester 
	 */
	static icalOverlapsWithSemester(ical, semester) {
		var dtstart = Util.parseDate(ical.dtstart);
		var until = ical.until ? Util.parseDate(ical.until) : null;
		var fromDate = Util.parseDate(semester.fromDate);
		var toDate = Util.parseDate(semester.toDate);
		return dtstart >= fromDate && dtstart <= toDate
			|| dtstart <= fromDate && !until
			|| until && until >= fromDate && until <= toDate;
	}

	static getTimezone() {
		var timezoneOffset = -new Date().getTimezoneOffset() / 60;
		return timezoneOffset;
	}

	/**
	 * Check if a date is within a range
	 * @param {*} date 
	 * @param {*} fromDate 
	 * @param {*} toDate 
	 */
	static dayIsWithinRange(date, fromDate, toDate) {
		date = Util.parseDate(date);
		fromDate = Util.parseDate(fromDate || "1900-01-01");
		toDate = Util.parseDate(toDate || "2999-01-01");
		toDate.setHours(23);
		toDate.setMinutes(59);
		toDate.setSeconds(59);
		return date >= fromDate && date <= toDate;
	}

	/**
	 * Convert to datetime string
	 * @param {date} value 
	 * @param {string} format
	 */
	static dateTimeText(value, format) {
		if (!value) return "";
		var t = value;
		if (typeof value == "string")
			t = Util.parseDate(value);
		else if (Number.isInteger(value))
			t = new Date(value * 1000);
		return moment(t).format(format || "LLL");
	}

	/**
	 * Convert integer to time string
	 * @param {number} value 
	 */
	static timeText(value) {
		if (!value) return "";
		return moment(Util.intToTime(value)).format("LT");
	}

	/**
	 * Convert to date string
	 * @param {date} value 
	 */
	static dateText(value) {
		if (!value) return "";
		return Util.dateTimeText(value, "MM/DD/YYYY");
	}

	/**
	 * Convert integer to time span string
	 * @param {number} value 
	 */
	static timeSpanText(value) {
		var h = Math.floor(value / 3600);
		var m = Math.floor((value - h * 3600) / 60 + 0.5);
		if (m == 60) {
			h++;
			m = 0;
		}
		return isNaN(h) || isNaN(m) ? '--h --m' : `${h}h ${m >= 10 ? m : '0' + m}m`;
	}

	static By = { "hour": 3600, "minute": 60, "second": 1 };

	/**
	 * compare time by hour, minute or second
	 * @param {Int} t1 - Int time < 86400
	 * @param {Int} t2 - Int time < 86400
	 * @param {String} by - "hour", "minute", "second"
	 */
	static compareTime(t1, t2, by = "minute") {
		const interval = Util.By[by];
		return Math.floor(t1 / interval) < Math.floor(t2 / interval);
	}

	/**
	 * Check if a date belongs to RRule
	 * @param {date} day 
	 * @param {object} rule 
	 */
	static dayOfRule(day, rule) {
		var r = rule.between(day, day, true);
		return r.length > 0;
	}

	/**
	 * Format number
	 * @param {Number} number 
	 * @param {Number} decimals 
	 */
	static formatNum(number, decimals) {
		var d = Math.pow(10, decimals || 0);
		return Math.round(number * d) / d;
	}

	/**
	 * Format distance value
	 * @param {Number} distance
	 * @param {Boolean=} imperial
	 * @param {Number=} decimals
	 * @param {String=} defaultValue
	 */
	static formatDistance(distance, imperial, decimals, defaultValue) {
		var decimals = decimals || 0;
		var defaultValue = defaultValue || "-";
		imperial = typeof imperial == "undefined" ? true : (imperial ? true : false);
		if (!distance)
			return defaultValue;
		if (imperial)
			return distance > 304.8 ? Util.formatNum(distance / 1600, decimals) + " mi" : Util.formatNum(distance / 0.3048, 0) + " ft";
		else
			return distance > 1000 ? Util.formatNum(distance / 1000, decimals) + " km" : Util.formatNum(distance, 0) + " m";
	}

	/**
	 * Check if is an integer
	 * @param {*} data 
	 */
	static isInteger(data) {
		return Number.isInteger(data);
	}

	static isNumber(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	/**
	 * Convert weekday object to integer using byte operation
	 * @param {object} value 
	 */
	static weekdaysToInt(value) {
		if (value === null) return null;
		var r = (value.sun ? 1 : 0) +
			(value.mon ? 2 : 0) +
			(value.tue ? 4 : 0) +
			(value.wed ? 8 : 0) +
			(value.thu ? 16 : 0) +
			(value.fri ? 32 : 0) +
			(value.sat ? 64 : 0);
		return r;
	}

	/**
	 * Convert integer to weekday object
	 * @param {number} value 
	 */
	static intToWeekdays(value) {
		if (value === null) return null;
		var r = {};
		r.sun = value & 1 ? true : false;
		r.mon = value & 2 ? true : false;
		r.tue = value & 4 ? true : false;
		r.wed = value & 8 ? true : false;
		r.thu = value & 16 ? true : false;
		r.fri = value & 32 ? true : false;
		r.sat = value & 64 ? true : false;
		return r;
	}

	/**
	 * Convert angle to direction text
	 * @param {Number} angle 
	 * @param {Boolean} fourdirection
	 */
	static getDirectionText(angle, fourdirection) {
		if (fourdirection) {
			if (angle <= 45 || angle > 315)
				return "north";
			else if (angle <= 135)
				return "east";
			else if (angle <= 225)
				return "south";
			else
				return "west";
		} else {
			if (angle <= 22.5 || angle > 337.5)
				return "north";
			else if (angle <= 67.5)
				return "northeast";
			else if (angle <= 112.5)
				return "east";
			else if (angle <= 157.5)
				return "southeast";
			else if (angle <= 202.5)
				return "south";
			else if (angle <= 247.5)
				return "southwest";
			else if (angle <= 292.5)
				return "west";
			else if (angle <= 337.5)
				return "northwest";
		}
	}

	/**
	 * Get the angle of two points
	 * from North, clockwise
	 * @param {{lat: Number, lng: Number}|Number[]} p1
	 * @param {{lat: Number, lng: Number}|Number[]} p2
	 * @return {Number}
	 */
	static getDirectionAngle(p1, p2) {
		if (Array.isArray(p1) && p1.length == 2)
			p1 = { lng: p1[0], lat: p1[1] };
		if (Array.isArray(p2) && p2.length == 2)
			p2 = { lng: p2[0], lat: p2[1] };

		var ang;
		var dx = p2.lng - p1.lng,
			dy = p2.lat - p1.lat;
		if (dx == 0) {
			ang = dy > 0 ? 90 : 270;
		} else if (dy == 0) {
			ang = dx > 0 ? 0 : 180;
		} else {
			ang = Math.atan(dy / dx) / Math.PI * 180;
			if (dx > 0 && dy < 0)
				ang = 360 + ang;
			else if (dx < 0)
				ang = 180 + ang;
		}
		ang = 450 - ang;
		if (ang >= 360) ang -= 360;
		return ang;
	}


	/**
	 * Find a element in array
	 * @param {Array} array 
	 * @param {string} property 
	 * @param {any} value 
	 */
	static findArray(array, property, value) {
		if (!Array.isArray(array)) return {};
		return array.find(e => e[property] == value);
	}

	static fromSimpleList(list) {
		var fields = list.fields;
		list = list.list.map(items => {
			var r = {};
			for (var i = 0; i < fields.length; i++) {
				var f = fields[i];
				var p = f.indexOf('.');
				if (p == -1)
					r[f] = items[i];
				else {
					var f1 = f.substr(0, p);
					var f2 = f.substr(p + 1);
					if (Util.isNumber(f2)) {
						r[f1] = r[f1] || [];
						if (typeof items[i] != "undefined")
							r[f1][f2] = items[i];
					} else {
						if (typeof items[i] == "undefined")
							r[f1] = null;
						else {
							r[f1] = r[f1] || {};
							r[f1][f2] = items[i];
						}
					}
				}
			}
			fields.forEach((f, i) => {
				if (f.indexOf(".") == -1)
					r[f] = items[i];
			});
			return r;
		});
		return list;
	}

	static hasField(field) {
		return typeof field != "undefined";
	}

	/**
	 * Get string for comparison
	 * @param {*} obj 
	 */
	static getCompareString(obj) {
		return (obj || "").toString().trim().toLowerCase();
	}

	/**
	 * Show alert box
	 * @param {String} message 
	 */
	static alert(message) {
		return new Promise((res, rej) => {
			mbox.custom({
				message: message,
				buttons: [{
					label: 'OK',
					color: 'secondary-color white-text',
					callback: () => {
						mbox.close();
						res();
					}
				}]
			});
		});
	}

	/**
	 * Show yes/no mbox dialog
	 * @param {string} message 
	 */
	static confirm(message) {
		return new Promise((res, rej) => {
			mbox.custom({
				message: message,
				buttons: [{
					label: 'Yes',
					color: 'secondary-color white-text',
					callback: () => {
						mbox.close();
						res(true);
					}
				}, {
					label: 'No',
					color: 'grey white-text',
					callback: () => {
						mbox.close();
						res(false);
					}
				}]
			});
		});
	}

	static prompt(message) {
		return new Promise(res => {
			mbox.prompt(message, name => res(name));
			setTimeout(() => {
				$(".mbox input")
					.eq(0).focus()
					.keyup(event => {
						if (event.keyCode === 13)
							$(".mbox .mbox-ok-button").click();
						else if (event.keyCode === 27)
							$(".mbox .mbox-cancel-button").click();
					});
			}, 100);
		});
	}

	/**
	 * Generate UUID
	 */
	static uuid() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	/**
	 * Generate random string
	 * @param {Number} length 
	 */
	static randomString(length) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890123456789";
		
		for (var i = 0; i < length; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		
		return text;
	}

	/**
	 * Compare two strings, used in sorting
	 * @param {String} s1 
	 * @param {String} s2 
	 */
	static compareString(s1, s2) {
		s1 = s1.trim().toLowerCase().split(/\s+/);
		s2 = s2.trim().toLowerCase().split(/\s+/);
		var size = Math.max(s1.length, s2.length);
		for (var i = 0; i < size; i++) {
			var e1 = s1[i],
				e2 = s2[i];
			if (e1 && !e2) {
				return 1;
			} else if (!e1 && e2) {
				return -1;
			} else if (!isNaN(e1) && !isNaN(e2)) {
				var v = Number(e1) - Number(e2);
				if (v != 0) return v;
			} else {
				var n1 = e1.match(/\d+$/),
					n2 = e2.match(/\d+$/);
				var v;
				if (n1 && n2) {
					var h1 = e1.replace(/\d+$/, ''),
						h2 = e2.replace(/\d+$/, '');
					v = h1.localeCompare(h2) || (Number(n1) - Number(n2));
				} else {
					v = e1.localeCompare(e2);
				}
				if (v != 0) return v;
			}
		}
	}

	/**
	 * Generate a random hex color
	 */
	static randomColor() {
		var c = "000000" + Math.floor(Math.random() * 16777216).toString(16);
		return "#" + c.substring(c.length - 6, c.length);
	}

	/**
	 * Capitalize a string
	 * @param {String} str 
	 */
	static capitalize(str) {
		str = str.split(' ').map(s => s[0].toUpperCase() + s.substr(1)).join(' ');
		return str;
	}

	/**
	 * Create string key for search
	 * @param {String} str 
	 */
	static makeKey(str) {
		return str.toLowerCase().trim().replace(/[^0-9a-z\s-\/]/g, '').replace(/\s+/g, ' ');
	}

	/**
	 * Get soundex of a string
	 * @param {string} s 
	 */
	static soundex(s) {
		var a = s.toLowerCase().split(''),
			f = a.shift(),
			r = '',
			codes = {
				a: '', e: '', i: '', o: '', u: '',
				b: 1, f: 1, p: 1, v: 1,
				c: 2, g: 2, j: 2, k: 2, q: 2, s: 2, x: 2, z: 2,
				d: 3, t: 3,
				l: 4,
				m: 5, n: 5,
				r: 6
			};

		r = f + a.map(function(v, i, a) {
				return codes[v]
			})
			.filter(function(v, i, a) {
				return ((i === 0) ? v !== codes[f] : v !== a[i - 1]);
			})
			.join('');

		return (r + '000').slice(0, 4).toUpperCase();
	};

	static lineDistance(pfrom, pto) {
		var toRadians = val => val * Math.PI / 180;
		var R = 6371000; // metres
		var phi1 = toRadians(pfrom.lat);
		var phi2 = toRadians(pto.lat);
		var deltaPhi = toRadians((pfrom.lat - pto.lat));
		var deltaLambda = toRadians((pfrom.lng - pto.lng));

		var a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
			Math.cos(phi1) * Math.cos(phi2) *
			Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		return R * c;
	}

	/**
	 * Convert length to meter
	 * @param {Number} distance 
	 * @param {String} unit 
	 */
	static convertDistance(distance, unit) {
		if (!unit || unit == "ft" || unit == "feet")
			return distance * 0.3048;
		if (unit == "mi" || unit == "miles")
			return distance * 1609.34;
		if (unit == "km" || unit == "kilometers")
			return distance * 1000;
		return distance;
	}

	/**
	 * Calculate edit distance
	 * @param {string} s 
	 * @param {string} t 
	 */
	static levDist(s, t) {
		var d = [];

		// Step 1
		var n = s.length;
		var m = t.length;

		if (n == 0) return m;
		if (m == 0) return n;

		//Create an array of arrays in javascript (a descending loop is quicker)
		for (var i = n; i >= 0; i--) d[i] = [];

		// Step 2
		for (var i = n; i >= 0; i--) d[i][0] = i;
		for (var j = m; j >= 0; j--) d[0][j] = j;

		// Step 3
		for (var i = 1; i <= n; i++) {
			var s_i = s.charAt(i - 1);

			// Step 4
			for (var j = 1; j <= m; j++) {

				//Check the jagged ld total so far
				if (i == j && d[i][j] > 4) return n;

				var t_j = t.charAt(j - 1);
				var cost = (s_i == t_j) ? 0 : 1; // Step 5

				//Calculate the minimum
				var mi = d[i - 1][j] + 1;
				var b = d[i][j - 1] + 1;
				var c = d[i - 1][j - 1] + cost;

				if (b < mi) mi = b;
				if (c < mi) mi = c;

				d[i][j] = mi; // Step 6

				//Damerau transposition
				if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
					d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
				}
			}
		}

		// Step 7
		return d[n][m];
	}

	/**
	 * Convert csv string to array
	 * @param {string} strData 
	 * @param {string} strDelimiter 
	 */
	static csv2array(strData, strDelimiter) {
		strDelimiter = (strDelimiter || ",");
		// Create a regular expression to parse the CSV values.
		var objPattern = new RegExp(
			(
				// Delimiters.
				"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
				// Quoted fields.
				"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
				// Standard fields.
				"([^\"\\" + strDelimiter + "\\r\\n]*))"
			),
			"gi"
		);
		// Create an array to hold our data. Give the array
		// a default empty first row.
		var arrData = [
			[]
		];
		// Create an array to hold our individual pattern
		// matching groups.
		var arrMatches = null;
		// Keep looping over the regular expression matches
		// until we can no longer find a match.
		while (arrMatches = objPattern.exec(strData)) {
			// Get the delimiter that was found.
			var strMatchedDelimiter = arrMatches[1];
			// Check to see if the given delimiter has a length
			// (is not the start of string) and if it matches
			// field delimiter. If id does not, then we know
			// that this delimiter is a row delimiter.
			if (
				strMatchedDelimiter.length &&
				(strMatchedDelimiter != strDelimiter)
			) {
				// Since we have reached a new row of data,
				// add an empty row to our data array.
				arrData.push([]);
			}
			// Now that we have our delimiter out of the way,
			// let's check to see which kind of value we
			// captured (quoted or unquoted).
			if (arrMatches[2]) {
				// We found a quoted value. When we capture
				// this value, unescape any double quotes.
				var strMatchedValue = arrMatches[2].replace(
					new RegExp("\"\"", "g"),
					"\""
				);
			} else {
				// We found a non-quoted value.
				var strMatchedValue = arrMatches[3];
			}
			// Now that we have our value string, let's add
			// it to the data array.
			arrData[arrData.length - 1].push(strMatchedValue);
		}
		// Return the parsed data.
		return (arrData);
	}

	static weekdayNames = {
		0: "Sunday",
		1: "Monday",
		2: "Tuesday",
		3: "Wednesday",
		4: "Thursday",
		5: "Friday",
		6: "Saturday"
	}

	static months = [
		{ id: 1, name: "January", numDays: 31 },
		{ id: 2, name: "February", numDays: 28 },
		{ id: 3, name: "March", numDays: 31 },
		{ id: 4, name: "April", numDays: 30 },
		{ id: 5, name: "May", numDays: 31 },
		{ id: 6, name: "June", numDays: 30 },
		{ id: 7, name: "July", numDays: 31 },
		{ id: 8, name: "August", numDays: 31 },
		{ id: 9, name: "September", numDays: 30 },
		{ id: 10, name: "October", numDays: 31 },
		{ id: 11, name: "November", numDays: 30 },
		{ id: 12, name: "December", numDays: 31 }
	]

	static sameAddress(addr1, addr2) {
		var a1 = Util.makeKey(addr1.address);
		var a2 = Util.makeKey(addr2.address);
	
		var directions = ['west', 'north', 'east', 'south', 'southwest', 'southeast', 'northwest', 'northeast', 's', 'n', 'w', 'e', 'ne', 'nw', 'se', 'sw'];
		
		for (var dir of directions) {
			var reg = new RegExp("(\\d+)\\s?" + dir + "\\b");
			a1 = a1.replace(reg, "$1");
			a2 = a2.replace(reg, "$1");
		}
	
		var p1 = a1.match(/^[a-z]?\d+ \w/);
		var p2 = a2.match(/^[a-z]?\d+ \w/);
		return (a1 == a2 || p1 && p2 && p1[0] == p2[0]) ? true : false;
	}

	/**
	 * Clone object
	 * @param {object} x 
	 */
	static clone(x) {
		return JSON.parse(JSON.stringify(x));
	}

	/**
	 * Send form post request
	 * @param {string} path 
	 * @param {object} params 
	 */
	static post(path, params) {
		var form = document.createElement("form");
		form.method = "POST";
		form.action = path;
		form.target = '_blank';

		for(var key in params) {
			if(params.hasOwnProperty(key)) {
				var hiddenField = document.createElement("input");
				hiddenField.setAttribute("type", "hidden");
				hiddenField.setAttribute("name", key);
				hiddenField.setAttribute("value", params[key]);
				form.appendChild(hiddenField);
			}
		}

		document.body.appendChild(form);
		form.submit();
		form.remove();
	}

	/**
	 * Deep object comparison
	 * @param {object} obj1 
	 * @param {object} obj2 
	 */
	static deepCompare(obj1, obj2) {
		var i, l;
		var leftChain = [];
		var rightChain = [];
		return compare2Objects(obj1, obj2);

		function compare2Objects(x, y) {
			var p;

			// remember that NaN === NaN returns false
			// and isNaN(undefined) returns true
			if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
				return true;
			}

			// Compare primitives and functions.     
			// Check if both arguments link to the same object.
			// Especially useful on the step where we compare prototypes
			if (x === y) {
				return true;
			}

			// Works in case when functions are created in constructor.
			// Comparing dates is a common scenario. Another built-ins?
			// We can even handle functions passed across iframes
			if ((typeof x === 'function' && typeof y === 'function') ||
				(x instanceof Date && y instanceof Date) ||
				(x instanceof RegExp && y instanceof RegExp) ||
				(x instanceof String && y instanceof String) ||
				(x instanceof Number && y instanceof Number)) {
				return x.toString() === y.toString();
			}

			// At last checking prototypes as good as we can
			if (!(x instanceof Object && y instanceof Object)) {
				return false;
			}

			if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
				return false;
			}

			if (x.constructor !== y.constructor) {
				return false;
			}

			if (x.prototype !== y.prototype) {
				return false;
			}

			// Check for infinitive linking loops
			if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
				return false;
			}

			// Quick checking of one object being a subset of another.
			// todo: cache the structure of arguments[0] for performance
			for (p in y) {
				if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
					return false;
				} else if (typeof y[p] !== typeof x[p]) {
					var rpx = false;
					if (!isNaN(x[p]) && !isNaN(y[p]) && x[p] == y[p])
						rpx = true;
					return rpx;
				}
			}

			for (p in x) {
				if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
					return false;
				} else if (typeof y[p] !== typeof x[p]) {
					return false;
				}

				switch (typeof(x[p])) {
					case 'object':
					case 'function':

						leftChain.push(x);
						rightChain.push(y);

						if (!compare2Objects(x[p], y[p])) {
							return false;
						}

						leftChain.pop();
						rightChain.pop();
						break;

					default:
						if (x[p] !== y[p]) {
							return false;
						}
						break;
				}
			}

			return true;
		}
	}

	static clearMouseEvents(selector) {
		$(selector).each((i, e) => {
			var handlers = $._data(e, "events");
			if (handlers) {
				handlers.mouseover.length = 0;
				handlers.mouseout.length = 0;
			}
		});
	}

	static download(filename, text) {
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
		element.setAttribute('download', filename);
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	static booleanText(value) {
		return value ? "Y" : "N";
	}
}