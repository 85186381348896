import { bindable } from 'aurelia-framework';
import { BaseComponent } from '../shared/components/base.component';
import { DistrictService } from '../shared/services/district';

export class Sponsorship extends BaseComponent {
	static inject() {
		return [ DistrictService ];
	}

	constructor(ds, ...rest) {
		super(...rest);
		this.ds = ds;
	}

	attached() {
	}
}