import { Api } from "./api";

export class DistrictService {
	static inject() {
        return [ Api ];
    }

    constructor(api) {
        this.api = api;
    }

    /**
     * Get nearest sales
     * @param {object} data 
     */
    async getNearestSales(data) {
        var r = await this.api.post("/api/district/init", data);
        return r;
    }

    /**
     * Find district
     * @param {object} data - contains latLng object and grade
     */
    async getDistrictByPoint(data) {
        var r = await this.api.post("/api/district/locate", data);
        return r;
    }

    /**
     * Find school
     * @param {object} data 
     */
    async getSchoolByPoint(data) {
        var r = await this.api.post("/api/school/locate", data);
        return r;
    }

    /**
     * Find nearest schools of a given point
     * @param {object} data
     */
    async findNearestSchools(data) {
        var r = await this.api.post("/api/schools/search", data);
        return r;
    }

    /**
     * Get full school object
     * @param {number} id 
     */
    async getSchoolById(id) {
        var r = await this.api.get("/api/school/" + id);
        return r;
    }
    
}