import { bindable } from 'aurelia-framework';
import { BaseComponent } from './base.component';

export class RecurrenceText extends BaseComponent {
	constructor(...rest) {
		super(...rest);
	}

	@bindable model = null;
	@bindable hideRange = false;
}