import { EventAggregator } from 'aurelia-event-aggregator';
import { Util } from './util';

export class Map {
	static inject() {
		return [EventAggregator];
	}

	constructor(ea) {
		this._ea = ea;
		this._mapSources = {};
		this._mapObjects = [];
		this._mapLayers = [];

		this.mapObj = null;
		this.canvas = null;

		this._loaded = false;	
		this._pinning = false;
		this._nextId = 1;

		this._clickEvents = [];
		this._mouseMoveEvents = [];

		this._firstLineLayer = "";
		this._popups = [];
		this._dragOn = false;
		this._isDragging = false;
		this._dragObj = {};
		this._org = null;
		this._marker = {};

		this._dragHandler = {
			mouseenter: () => this.mapObj.dragPan.disable(),
			mouseleave: () => this.mapObj.dragPan.enable()
		}
	}

	colors = ['#00f', '#0f0', '#f00', '#0f8', '#73beff', '#0ff', '#80f', '#f08', '#808', '#f88', '#8f0', '#880', '#ff0', '#f0f', '#0ff', '#000'];

	popupEnabled = true;

	init() {
		try {
			this.mapObj = new mapboxgl.Map({
				container: 'map',
				style: ezMap,
				center: defaultLocation || [-98.156451, 39.723628],
				zoom: defaultLocation ? 12 : 4,
				maxZoom: 19
			});
			this.mapObj.addControl(new mapboxgl.ScaleControl({
				maxWidth: 80,
				unit: 'imperial'
			}), 'bottom-right');
			this.mapObj.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

			this.mapObj.on("load", () => this._mapLoaded());
			this.canvas = this.mapObj.getCanvasContainer();
			window.map = this.mapObj;
		} catch (error) {
			this._error = true;
		}
	}

	ready() {
		return new Promise((res, rej) => {
			var waitForLoaded = () => {
				if (this._error)
					rej({ message: "Map initialization failed" });
				if (this._loaded)
					res();
				else
					setTimeout(waitForLoaded, 100);
			};
			waitForLoaded();
		});
	}

	/**
	 * Add click event handler
	 * @param {function} h 
	 */
	addClickEvent(h) {
		this._clickEvents.push(h);
	}

	/**
	 * Remove click event handler
	 * @param {function} h 
	 */
	removeClickEvent(h) {
		var p = this._clickEvents.indexOf(h);
		if (p >= 0)
			this._clickEvents.splice(p, 1);
	}

	addMoveEvent(name) {
		var p = this._mouseMoveEvents.indexOf(name);
		if (p == -1)
			this._mouseMoveEvents.push(name);
	}

	removeMoveEvent(name) {
		var p = this._mouseMoveEvents.indexOf(name);
		if (p >= 0)
			this._mouseMoveEvents.splice(p, 1);
	}

	/**
	 * Clear everything on the map
	 */
	clear() {
		for (var i in this._mapObjects)
			this._mapObjects[i].remove();
		for (var i in this._mapLayers)
			this.removeLayer(this._mapLayers[i]);

		this._clickEvents = [];
		this._mouseMoveEvents = [];
		this._mapSources = {};
		this._mapObjects.length = 0;
		this._mapLayers.length = 0;
		this._firstLineLayer = "";
		
		this.highlight();
		this._ea.publish("mapCleared");
	}

	/**
	 * Check if map layer exists
	 * @param {string} name 
	 * @returns {boolean}
	 */
	layerExists(name) {
		return this.mapObj.getLayer(name) ? true : false;
	}

	/**
	 * Remove map layer by name
	 * @param {string} name 
	 */
	removeLayer(name) {
		if (this.mapObj.getLayer(name)) this.mapObj.removeLayer(name);
		if (this.mapObj.getLayer(name + "-label")) this.mapObj.removeLayer(name + "-label");
		if (this.mapObj.getLayer(name + "-symb")) this.mapObj.removeLayer(name + "-symb");
		if (this.mapObj.getLayer(name + "-cluster")) this.mapObj.removeLayer(name + "-cluster");
		if (this.mapObj.getLayer(name + "-cluster-count")) this.mapObj.removeLayer(name + "-cluster-count");
		if (this.mapObj.getLayer(name + "-border")) this.mapObj.removeLayer(name + "-border");
		if (this.mapObj.getLayer(name + "-border-label")) this.mapObj.removeLayer(name + "-border-label");
		if (this.mapObj.getSource(name)) this.mapObj.removeSource(name);
		delete this._mapSources[name];
		this.removeMoveEvent(name);
		this.removeMoveEvent(name + "-label");
		this.removeMoveEvent(name + "-cluster");
	}

	/**
	 * Redraw map, fix navigation tools position
	 */
	resize() {
		this.mapObj.resize();
	}

	/**
	 * Get map center
	 * @returns {object}
	 */
	getCenter() {
		return this.mapObj.getCenter();
	}

	/**
	 * Center map on a location
	 * @param {object} point 
	 * @param {number} zoom -1 means do not zoom
	 */
	centerOnMap(point, zoom) {
		if (point && (point.lat || point.lng)) {
			var target = {
				center: [point.lng, point.lat],
				speed: 6
			}
			if (!zoom || zoom > 0)
				target.zoom = zoom || 16;
			this.mapObj.flyTo(target);
		}
	}

	/**
	 * Fit all points on the map
	 * @param {object[]} points lat/lng points
	 * @param {number} duration
	 * @param {function} next callback function
	 */
	fitBounds(points, duration, next) {
		if (!points.length || !Array.isArray(points)) return;

		var bounds = [200, 200, -200, -200];
		points.forEach(a => {
			var lng = Number(a.lng);
			var lat = Number(a.lat);
			if (!lng && !lat) return;
			if (lng < bounds[0]) bounds[0] = lng;
			if (lat < bounds[1]) bounds[1] = lat;
			if (lng > bounds[2]) bounds[2] = lng;
			if (lat > bounds[3]) bounds[3] = lat;
		});

		var dLng = (bounds[2] - bounds[0]) / 2 || 0.025;
		var dLat = (bounds[3] - bounds[1]) / 2 || 0.025;

		this.mapObj.fitBounds([
			[bounds[0] - dLng, bounds[1] - dLat],
			[bounds[2] + dLng, bounds[3] + dLat]
		], {
			duration: duration || 300,
			easing: t => {
				if (t == 1 && typeof next == "function")
					next();
				return t;
			}
		});
	}

	/**
	 * Change map layer visibility
	 * @param {string} name 
	 * @param {boolean} visibility 
	 */
	setLayerVisibility(name, visibility) {
		if (this.mapObj.getLayer(name))		
			this.mapObj.setLayoutProperty(name, 'visibility', visibility ? "visible" : "none");
	}

	/**
	 * Display points on the map
	 * @param {object} options
	 */
	addPoints(options) {
		if (typeof options.label == "undefined") options.label = true;
		if (!options.points || !options.points.length) {
			this.removeLayer(options.name);
			return;
		}

		options.points = options.points.filter(pt => pt.lng || pt.lat);

		var features = [];
		options.points.forEach(pt => {
			var p = Util.clone(pt);
			p._uuid = Util.uuid();
			p.title = options.title ? options.title(p) : "";
			features.push({
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [p.lng, p.lat]
				},
				properties: p
			});
		});

		var source = this._mapSources[options.name];
		if (source) {
			source.data.features = features;
			this.mapObj.getSource(options.name).setData(source.data);
			return;
		}

		var source = {
			type: "geojson",
			data: {
				"type": "FeatureCollection",
				features: features
			}
		};
		if (options.cluster) {
			source.cluster = true;
			source.clusterMaxZoom = 14;
			source.clusterRadius = 40;
		}
		this.mapObj.addSource(options.name, source);
		this._mapSources[options.name] = source;

		var colors = [
			['student', '#6993ef'],
			['staff', '#6993ef'],
			['school', '#FF5BDD'],
			['depot', '#9D6CE8'],
			['stop', '#E87814'],
			['new', '#D1AA24'],
			['d2d', '#FFC076'],
			['edit', '#f00']
		];
		this.colors.forEach((c, i) => {
			colors.push(['color-' + i, c]);
		});

		this.mapObj.addLayer({
			id: options.name,
			type: "circle",
			source: options.name,
			filter: ["!has", "point_count"],
			paint: {
				"circle-radius": options.radius || 6,
				"circle-color": options.colorField ? {
						property: options.colorField,
						type: 'identity'
					} : {
						property: 'datatype',
						type: 'categorical',
						stops: colors
					},
				"circle-stroke-width": Number.isInteger(options.stroke) ? options.stroke : 2,
				"circle-stroke-color": "#fff"
			}
		}, options.before);

		if (options.cluster) {
			var clusterColor = colors.find(x => x[0] == options.clusterColor);
			clusterColor = clusterColor ? clusterColor[1] : "#6993ef";
			this.mapObj.addLayer({
				id: options.name + "-cluster",
				type: "circle",
				source: options.name,
				filter: ["has", "point_count"],
				paint: {
					"circle-color": clusterColor,
					"circle-radius": {
						property: "point_count",
						type: "interval",
						stops: [
							[0, 15],
							[20, 20],
							[50, 25],
							[100, 30],
							[500, 35]
						]
					},
					"circle-stroke-width": 2,
					"circle-stroke-color": "#fff"
				}
			});

			this.mapObj.addLayer({
				id: options.name + "-cluster-count",
				type: "symbol",
				source: options.name,
				filter: ["has", "point_count"],
				layout: {
					"text-field": "{point_count_abbreviated}",
					"text-size": 14,
					"text-font": ["Open Sans Semibold"]
				},
				paint: {
					"text-color": "#fff"
				}
			});
		}

		if (options.label)
			this.mapObj.addLayer({
				id: options.name + "-label",
				type: "symbol",
				source: options.name,
				layout: {
					"text-field": "{title}",
					"text-font": ["Open Sans Semibold"],
					"text-offset": [0, options.textOffset || 1.1],
					"text-size": options.textSize || 12
				},
				paint: {
					"text-opacity": options.textOpacity || 1,
					"text-color": options.textColor || "#000000"
				}
			});

		this._trackLayer(options.name);

		this._clickEvents.push(e => {
			if (!this.mapObj.getLayer(options.name)) return;
			this.clearPopups();

			var features = this.mapObj.queryRenderedFeatures(e.point, {
				layers: options.label ? [options.name, options.name + '-label'] : [options.name]
			});
			if (features && features.length) {
				var point = features[0].properties;
				if (options.popup && !this._clicking && this.popupEnabled) {
					var content = options.popup(point);
					var loc = point.lat && point.lng ? point : e.lngLat;
					this.addPopup(loc, content, options.popupHandlers);
				}
				if (options.click) {
					options.click(point);
				}
				return;
			}

			if (!this.mapObj.getLayer(options.name + '-cluster'))
				return;
				
			var features = this.mapObj.queryRenderedFeatures(e.point, {
				layers: [options.name + '-cluster']
			});
			if (features && features.length) {
				var center = {
					lng: features[0].geometry.coordinates[0],
					lat: features[0].geometry.coordinates[1]
				};
				var count = features[0].properties.point_count;
				var dist = p => (p.lat - center.lat) * (p.lat - center.lat) + (p.lng - center.lng) * (p.lng - center.lng);
				options.points.forEach(p => p.d = dist(p));
				var list = options.points.sort((p1, p2) => p1.d - p2.d).slice(0, count);
				list.sort((p1, p2) => p1.lastName.localeCompare(p2.lastName) || p1.firstName.localeCompare(p2.firstName));

				this.clearPopups();
				if (options.clusterPopup && !this._clicking) {
					var content = options.clusterPopup(list, count, center);
					this.addPopup(center, content, options.popupHandlers);
				}
			}
		});

		this.addMoveEvent(options.name);
		if (options.label) this.addMoveEvent(options.name + '-label');
		if (options.cluster) this.addMoveEvent(options.name + '-cluster');
	}

	/**
	 * 
	 * @param {object} point - lat/lng
	 * @param {string} content - popup content
	 * @param {[function]} handlers 
	 */
	addPopup(point, content, handlers) {
		Promise.resolve(content)
			.then(content => {
				var id = Util.uuid();
				var popup = new mapboxgl.Popup({closeOnClick: false})
					.setLngLat([point.lng, point.lat])
					.setHTML(`<div id="${id}" class="marker-popup">${content}</div>`)
					.addTo(this.mapObj);
				popup.uuid = id;
				this._popups.push(popup);
				this._trackObject(popup);

				if (handlers) {
					var dom = $(`#${id} a`);
					dom.click(function() {
						var key = $(this).data("handler");
						handlers[key]($(this).data("value"))
					});
				}
			});
	}

	/**
	 * Remove all popups on the map
	 */
	clearPopups() {
		this._popups.forEach(function(p) {
			$(`#${p.id} a`).off(); // remove all event handlers
			p.remove();
		});
		this._popups = [];
	}

	/**
	 * Highlight a point with a pin
	 * @param {object} point - lngLat
	 * @param {object} options - contains name and visible
	 */
	highlight(point, options) {
		options = options || {};
		options.name = options.name || "default";
		options.visible = typeof options.visible == "undefined" ? true : options.visible;

		var elem = document.createElement("img");
		elem.src = "/images/pin.png";
		if (typeof point == "object" && point) {
			if (this._marker[options.name])
				this._marker[options.name].remove();
			if (options.visible)
				this._marker[options.name] = new mapboxgl.Marker(elem, { offset: [ 0, -15] })
					.setLngLat([point.lng, point.lat])
					.addTo(this.mapObj);
			else if (this._marker[options.name])
				this._marker[options.name].remove();
		} else {
			if (options && options.name && this._marker[options.name])
				this._marker[options.name].remove();
		}
	}

	/**
	 * Show line on the map
	 * @param {object} options
	 */
	addLine(options) {
		var list = [];
		for (var i in options.points)
			list.push([options.points[i].lng, options.points[i].lat]);

		if (!options.name) {
			options.name = "line" + this._nextId;
			this._nextId++;
		}

		var source = this._mapSources[options.name];
		if (source) {
			source.data.geometry.coordinates = list;
			this.mapObj.getSource(options.name).setData(source.data);
			return;
		}
		
		source = {
			type: "geojson",
			data: {
				type: "Feature",
				properties: options.properties || {},
				geometry: {
					type: "LineString",
					coordinates: list
				}
			}
		};
		this.mapObj.addSource(options.name, source);
		this._mapSources[options.name] = source;

		options = options || {};
		this.mapObj.addLayer({
			id: options.name,
			type: "line",
			source: options.name,
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-opacity": options.opacity || 0.5,
				"line-color": options.color || "#00F",
				"line-width": options.lineWidth || 4
			}
		}, options.before || this._firstLineLayer);

		this.mapObj.addLayer({
			id: options.name + '-symb',
			type: "symbol",
			source: options.name,
			layout: {
				"symbol-placement": "line",
				"symbol-spacing": 50,
				"text-field": ">",
				"text-keep-upright": false,
				"text-size": 24,
				"text-font": ["Open Sans Semibold"]
			},
			paint: {
				"text-color": options.color || "#00F"
			}
		});

		if (!this._firstLineLayer) this._firstLineLayer = options.name;
		this._trackLayer(options.name);
	}

	/**
	 * Set layer draggable
	 * @param {string} name - layer name
	 * @param {number} recordId 
	 * @param {boolean} on 
	 */
	setDraggable(name, recordId, on) {
		this._dragOn = on ? true : false;
		if (this._dragOn) {
			this._dragObj = {};
			this._dragObj.source = name;
			this._dragObj.id = recordId;
			this._dragObj.indexes = [];
			this.clearPopups();
		} else {
			this._isDragging = false;
		}

		var flist = this._mapSources[this._dragObj.source].data.features;
		var position = -1;
		flist.forEach((f, i) => {
			if (f.properties.id == this._dragObj.id) {
				position = i;
				if (this._dragOn) {
					this._org = f.properties.datatype;
					f.properties.datatype = 'edit';
				} else {
					f.properties.datatype = this._org;
				}
			}
		});
		if (position >= 0) {
			var item = flist[position];
			flist.splice(position, 1);
			flist.push(item);
		}
		this.mapObj.getSource(name).setData(this._mapSources[name].data);

		if (on) {
			this.mapObj.on("mouseenter", name, this._dragHandler.mouseenter);
			this.mapObj.on("mouseleave", name, this._dragHandler.mouseleave);
		} else {
			this.mapObj.off("mouseenter", name, this._dragHandler.mouseenter);
			this.mapObj.off("mouseleave", name, this._dragHandler.mouseleave);
		}

		if (!on && this._mapSources[name]) {
			return this._dragObj.indexes.map(index => {
				var c = flist[index].geometry.coordinates;
				var p = flist[index].properties;
				p.lng = c[0];
				p.lat = c[1];
				return p;
			});
		}
	}

	addSegments(segments, before) {
		var name = "segments";
		this.removeLayer(name);

		var features = [];
		segments.forEach(function(s) {
			features.push({
				type: "Feature",
				geometry: {
					type: "LineString",
					coordinates: s.seg
				},
				properties: {
					dist: s.dist
				}
			});
		});

		var source = {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: features
			}
		};
		this.mapObj.addSource(name, source);
		this._mapSources[name] = source;

		this.mapObj.addLayer({
			id: name,
			type: "line",
			source: name,
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-opacity": 0.6,
				"line-color": {
					property: 'dist',
					type: 'interval',
					stops: [
						[0, '#00f'],
						[3200, '#aaf']
					]
				},
				"line-width": 2
			}
		}, this._firstLineLayer || before);
		this._trackLayer(name);
	}

	polygon(coordinates, properties) {
		if (this.isMultiPolygon(coordinates))
			return coordinates.map(e => turf.polygon(e, properties));
		else
			return turf.polygon(coordinates, properties);
	}

	/**
	 * Add polygon on the map
	 * @param {object|object[]} features - feature or features
	 * @param {object} options
	 */
	addPolygon(features, options) {
		options.name = options.name || "polygon";

		features = Array.isArray(features) ? features : [features];
		features.forEach(f => {
			f.properties = f.properties || {};
			f.properties.color = f.properties.color || options.color || "#CC49B1";
			f.properties.opacity = f.properties.opacity || options.opacity || 0.5;
			f.properties.fillOpacity = f.properties.fillOpacity || options.fillOpacity || 0.01;
			f.properties.width = f.properties.width || options.width || 3;
		});

		var source = {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: features
			}
		};

		if (this._mapSources[options.name]) {
			this.mapObj.getSource(options.name).setData(source.data);
			return;
		}

		this.mapObj.addSource(options.name, source);
		this._mapSources[options.name] = source;

		this.mapObj.addLayer({
			id: options.name,
			type: "fill",
			source: options.name,
			layout: {},
			paint: {
				"fill-color": { property: "color", type: "identity" },
				"fill-opacity": { property: "fillOpacity", type: "identity" },
			}
		}, options.before);

		var paintStyle= {
			"line-color": { property: "color", type: "identity" },
			"line-opacity": { property: "opacity", type: "identity" },
			"line-width": { property: "width", type: "identity" }
		}
		if (options.dash)
			paintStyle["line-dasharray"] = options.dash;
		this.mapObj.addLayer({
			id: options.name + "-border",
			type: "line",
			source: options.name,
			layout: {},
			paint: paintStyle
		}, options.before);

		if (options.label) {
			this.mapObj.addLayer({
				id: options.name + "-border-label",
				type: "symbol",
				source: options.name,
				layout: {
					"symbol-placement": "line",
					"symbol-spacing": 10,
					"text-field": "{label}",
					"text-offset": [0, 0.5],
					"text-size": 10,
					"text-font": ["Open Sans Semibold"]
				},
				paint: {
					"text-color": { property: "color", type: "identity" }
				}
			}, options.before);
		}

		this._trackLayer(options.name);
	}

	/**
	 * Click any place on the map to fire
	 * @param {function} handler - pass in location as argument 
	 */
	clickLocation(handler) {
		var pick = e => {
			this.mapObj.off('click', pick);
			this._pinning = false;
			$('#map canvas').css('cursor', '');
			handler(e.lngLat);
		}
		this.mapObj.on('click', pick);
		this._pinning = true;
		$('#map canvas').css('cursor', 'pointer');
	}

	/**
	 * Click on the points, until double click the point
	 * @param {string} pointLayer
	 * @param {function} clicked 
	 * @param {function} doubleClicked 
	 */
	clickTillDoubleClick(pointLayer, clicked, doubleClicked) {
		var pick = e => {
			var features = this.mapObj.queryRenderedFeatures(e.point, {
				layers: [pointLayer, pointLayer + "-label"]
			});
			if (features.length) {
				clicked(features[0].properties);
			}
		}
		var lastPick = e => {
			var features = this.mapObj.queryRenderedFeatures(e.point, {
				layers: [pointLayer, pointLayer + "-label"]
			});
			if (features.length) {
				cancel();
				doubleClicked(features[0].properties);
			}
		}
		var cancel = () => {
			this.mapObj.off('click', pick);
			this.mapObj.off('dblclick', lastPick);
			this._clicking = false;
			this.mapObj.doubleClickZoom.enable();
		}

		this.mapObj.doubleClickZoom.disable();
		this._clicking = true;
		this.mapObj.on('click', pick);
		this.mapObj.on('dblclick', lastPick);

		return { cancel: cancel };
	}
	
	// ============== Data Validation ============= //

	isLinearRing(coordinates) {
		var isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);
	
		if (Array.isArray(coordinates) && coordinates.length >= 4) {
			var r = true;
			var len = coordinates.length;
			coordinates.forEach(e => {
				r = r && Array.isArray(e) && e.length == 2 && isNumber(e[0]) && isNumber(e[1]);
			});
			r = r && coordinates[0][0] == coordinates[len - 1][0] && coordinates[0][1] == coordinates[len - 1][1];
			if (r)
				coordinates[len - 1] = coordinates[0];
			return r;
		} else {
			return false;
		}
	}

	isPolygon(coordinates) {
		return Array.isArray(coordinates) && coordinates.length >= 1 && this.isLinearRing(coordinates[0]);
	}

	isMultiPolygon(coordinates) {
		if (Array.isArray(coordinates) && coordinates.length >= 2) {
			var r = true;
			coordinates.forEach(e => r = r && this.isPolygon(e));
			return r;
		} else {
			return false;
		}
	}

	/**
	 * Draw a polygon on the map
	 * @param {boolean} status - turn on or off
	 * @param {function} handler 
	 * @param {boolean} lineOnly - polygon or line
	 */
	startFreeDraw(status, handler, lineOnly) {
		if (!this._freeDraw) {
			this._freeDraw = {
				downLocation: null,
				points: [],
				length: 0
			};
			this._freeDraw.drawLine = () => {
				this.addLine({
					name: "freeDrawLine",
					lineWidth: 1,
					points: this._freeDraw.points
				});
			};
			this._freeDraw.mousedown = e => {
				this._freeDraw.downLocation = e.point;
			};
			this._freeDraw.mousemove = e => {
				if (this._freeDraw.points.length >= 1)
					this._freeDraw.points[this._freeDraw.length] = e.lngLat;
				if (this._freeDraw.points.length >= 2)
					this._freeDraw.drawLine();
			};
			this._freeDraw.mouseup = e => {
				if (e.point.x != this._freeDraw.downLocation.x || e.point.y != this._freeDraw.downLocation.y)
					return;

				if (e.originalEvent.button == 0) {
					// left button
					this._freeDraw.points.push(e.lngLat);
					this._freeDraw.length++;
				} else if (e.originalEvent.button == 2) {
					// right button
					this._freeDraw.points.pop();
					this._freeDraw.length--;
					this._freeDraw.points[this._freeDraw.length] = e.lngLat;
					this._freeDraw.drawLine();
				}
			};
			this._freeDraw.mousedblclick = e => {
				this.removeLayer("freeDrawLine");
				if (lineOnly) {
					var line = this._freeDraw.points.map(e => [e.lng, e.lat]);
					if (line.length > 1) {
						this.addLine({
							name: "freeDrawLine",
							lineWidth: 3,
							points: this._freeDraw.points
						});
						if (this._freeDraw.handler)
							this._freeDraw.handler(line);
					} else {
						if (this._freeDraw.handler)
							this._freeDraw.handler(null);
					}
				} else {
					var polygon = this._freeDraw.points.map(e => [e.lng, e.lat]);
					polygon.push(polygon[0]);
					if (polygon.length >= 4) {
						this.addPolygon(this.polygon([polygon]), {
							name: "freeDraw",
							color: "#00F",
							opacity: 0.1,
							width: 2
						});
						if (this._freeDraw.handler)
							this._freeDraw.handler(polygon);
					} else {
						if (this._freeDraw.handler)
							this._freeDraw.handler(polygon[0]);
					}
				}

				this._freeDraw.downLocation = null;
				this._freeDraw.points = [];
				this._freeDraw.length = 0;
			};
		}
		if (status) {
			this._freeDraw.handler = handler;
			if (!this._freeDraw.status) {
				this._freeDraw.status = true;
				this.mapObj.doubleClickZoom.disable();
				this.mapObj.on('mousedown', this._freeDraw.mousedown);
				this.mapObj.on('mousemove', this._freeDraw.mousemove);
				this.mapObj.on('mouseup', this._freeDraw.mouseup);
				this.mapObj.on('dblclick', this._freeDraw.mousedblclick);
			}
		} else {
			this._freeDraw.downLocation = null;
			this._freeDraw.status = false;
			this._freeDraw.points = [];
			this._freeDraw.length = 0;
			this.mapObj.doubleClickZoom.enable();
			this.removeLayer("freeDraw");
			this.removeLayer("freeDrawLine");
			this.mapObj.off('mousedown', this._freeDraw.mousedown);
			this.mapObj.off('mousemove', this._freeDraw.mousemove);
			this.mapObj.off('mouseup', this._freeDraw.mouseup);
			this.mapObj.off('dblclick', this._freeDraw.mousedblclick);
		}
	}

	//================== Private Members ==================//

	_mapLoaded() {
		this.mapObj.on('click', e => this._click(e));
		this.mapObj.on('mousedown', e => this._mousedown(e));
		this.mapObj.on('touchend', e => this._click(e));
		this.mapObj.on('mousemove', e => this._mousemove(e));
		this.mapObj.addLayer({
			'id': 'Imagery',
			'type': 'raster',
			'source': {
				'type': 'raster',
				'tiles': [
					'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
				],
				'tileSize': 256
			},
			'paint': {},                
		});
		this.mapObj.setLayoutProperty('Imagery', 'visibility', 'none');
		$('#satellite').click(e => this._toggleImagery(e));
		this._loaded = true;
	}

	_toggleImagery(e) {
		e.preventDefault();
		e.stopPropagation();
		var visibility = this.mapObj.getLayoutProperty('Imagery', 'visibility');

		if (visibility === 'visible') {
			this.mapObj.setLayoutProperty('Imagery', 'visibility', 'none');
			this.className = '';
			this.textContent = 'Imagery';
			$("#satellite").removeClass("map").addClass("satellite");
			$("#satellite label").text("Satellite");
			$("#satellite img").attr("src", "/images/basemap_imagery.jpg");
		} else {
			this.className = 'streets';
			this.textContent = 'Streets';
			this.mapObj.setLayoutProperty('Imagery', 'visibility', 'visible');
			$("#satellite").removeClass("satellite").addClass("map");
			$("#satellite label").text("Map");
			$("#satellite img").attr("src", "/images/basemap_streets.jpg");
		}
	}

	_mousedown(e) {
		if (this._dragOn) {
			var features = this.mapObj.queryRenderedFeatures(e.point, { layers: [this._dragObj.source] });
			if (features.length && features[0].properties.id == this._dragObj.id) {
				this._isDragging = true;
				for (var i in this._mapSources[this._dragObj.source].data.features)
					if (this._mapSources[this._dragObj.source].data.features[i].properties._uuid == features[0].properties._uuid)
					{
						this._dragObj.index = Number(i);
						if (this._dragObj.indexes.indexOf(this._dragObj.index) == -1)
							this._dragObj.indexes.push(this._dragObj.index);
						break;
					}
				this.mapObj.once('mouseup', e => {
					this._isDragging = false;
					delete this._dragObj.index;
				});
			}
		}
	}

	_click(e) {
		for (var hnd of this._clickEvents)
			hnd(e);
	}

	_mousemove(e) {
		if (this._isDragging) {
			var coord = e.lngLat;
			this._mapSources[this._dragObj.source].data.features[this._dragObj.index].geometry.coordinates = [coord.lng, coord.lat];
			this.mapObj.getSource(this._dragObj.source).setData(this._mapSources[this._dragObj.source].data);
		} else {
			var features = this.mapObj.queryRenderedFeatures(e.point, {
				layers: this._dragOn ? [this._dragObj.source] : this._mouseMoveEvents
			});
		}

		if (features && features.length || this._pinning) {
			$('#map canvas').css('cursor', 'pointer');
		} else {
			$('#map canvas').css('cursor', '');
		}
	}

	_trackObject(obj) {
		this._mapObjects.push(obj);
	}

	_trackLayer(name) {
		this._mapLayers.push(name);
	}
}