import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Map } from '../services/map';
import { Util } from '../services/util';

export class BaseComponent {
	static inject() {
		return [Router, EventAggregator, Map];
	}

	constructor(router, ea, map) {
		this.router = router;
		this.ea = ea;
		this.map = map;
		this.Util = Util;
	}

	grades = [
		{ id: 1, name : "PK" },
		{ id: 2, name : "KG" },
		{ id: 3, name : "01" },
		{ id: 4, name : "02" },
		{ id: 5, name : "03" },
		{ id: 6, name : "04" },
		{ id: 7, name : "05" },
		{ id: 8, name : "06" },
		{ id: 9, name : "07" },
		{ id: 10, name : "08" },
		{ id: 11, name : "09" },
		{ id: 12, name : "10" },
		{ id: 13, name : "11" },
		{ id: 14, name : "12" }
	];

	get currentUrl() {
		return this.router && this.router.currentInstruction ? this.router.currentInstruction.config.name : null;
	}

	selectedStatusIcon = {
		0: "fa-square-o",
		1: "fa-minus",
		2: "fa-check"
	}

	navigateTo(page, data) {
		if (page[0] == "/")
			this.router.navigate(page);
		else if (data)
			this.router.navigateToRoute(page, data);
		else
			this.router.navigateToRoute(page);
	}

	showMessage(type, message, duration) {
		switch (type) {
			case "message":
				Materialize.toast(message, duration || 8000);
				break;
			case "success": 
				Materialize.toast(message, duration || 8000, "green darken-2");
				break;
			case "warning":
				Materialize.toast(message, duration || 8000, "orange darken-3");
				break;
			case "error":
				Materialize.toast(message, duration || 8000, "red darken-3");
				break;
		}
	}
}