import {bindable, bindingMode, customElement} from 'aurelia-framework';
import {Util} from '../services/util'

export class Checkbox {
	static inject() {
		return [Element];
	}

	constructor(element) {
		this.element = element;
		this.id = Util.uuid();
	}

	@bindable({defaultBindingMode: bindingMode.twoWay}) checked = false;
	@bindable label;
	@bindable fixed;
	@bindable fixedValue;
	@bindable disabled;
	@bindable narrow;
	@bindable aligned;

	attached() {
		if (this.aligned || this.aligned === "")
			this.aligned = true;

	}

	checkedChanged() {
		setTimeout(() => {
			var e = document.createEvent("CustomEvent");
			e.initCustomEvent('change', true, true, this.file);
			this.element.dispatchEvent(e);
		}, 0);
	}
}