import { Api } from "./api";

export class EZRoutingService {
	static inject() {
		return [ Api ];
	}

	constructor(api) {
		this.api = api;
	}

	async getReferences() {
		var r = await this.api.get("/api/ezr/ref/" + window.client);
		this.district = r.district;
		this.schools = r.schools;
		this.grades = r.grades;
		this.settings = r.settings;
		this.transportPrograms = r.transportPrograms || [];
	}

	suggestAddress(address, point, client) {
		return this.api.get(`/api/ezr/address/suggest?address=${encodeURI(address)}&client=${client}`);
	}

	findMyBus(data) {
		return this.api.post(`/api/ezr/findmybus`, data);
	}
}