import { BaseComponent } from "../shared/components/base.component";
import { DistrictService } from "../shared/services/district";
import { EZRoutingService } from "../shared/services/ezr";

export class FindMyBus extends BaseComponent {
	static inject() {
		return [ EZRoutingService, DistrictService ];
	}

	constructor(ezr, ds, ...rest) {
		super(...rest);
		this.ezr = ezr;
		this.ds = ds;
		this.result = [];
		this.data = {};
		this.enabled = false;

		this.subs = [];
		this.subs.push(this.ea.subscribe("addressEntered", () => this.clearFmbResult()));
	}

	async activate() {
		await this.ezr.getReferences();
	}

	walkzone = null;
	insideWalkzone = null;
	insideSchoolBoundary = null;
	schoolColor = null;
	hideResult = null;

	async attached() {
		await this.map.ready();
		$('.tabs').tabs();

		if (this.ezr.district) {
			var polygons = this.map.polygon(this.ezr.district.geometry.coordinates, {label: "District Boundary"});
			this.map.addPolygon(polygons, {
				name: "school-district",
				width: 4,
				label: true,
				dash: [4, 1]
			});
			if (!Array.isArray(polygons)) polygons = [polygons];
			var allPoints = [];
			for (var polygon of polygons) {
				var points = polygon.geometry.coordinates[0].map(e => ({ lng: e[0], lat: e[1] }));
				allPoints = allPoints.concat(points);
			}
			this.fitPoints(allPoints);
		}
	}

	detached() {
		this.subs.forEach(s => s.dispose());
	}

	findMyBus(data) {
		this.insideDistrict = data.insideDistrict;
		if (!this.insideDistrict || data.checkInsideOnly) return;

		this.walkzone = data.walkzone;
		this.boundary = data.boundary;
		this.insideWalkzone = data.address && data.address.inside ? true : false;
		this.insideSchoolBoundary = data.address && data.address.insideSchoolBoundary ? true : false;
		this.schoolColor = data.schoolColor;
		this.hideResult = this.insideWalkzone || !this.insideSchoolBoundary;

		var stopById = {};
		data.stops.forEach(s => stopById[s.id] = s);
		for (var r of data.routes) {
			r.ical = this.Util.parseIcal(r.ical);
			r.fromTs = r.trip.tripstops[r.pudo[0]];
			r.toTs = r.trip.tripstops[r.pudo[1]];
			r.fromStop = stopById[r.fromTs.stopId];
			r.toStop = stopById[r.toTs.stopId];
		}

		this.address = data.address;
		this.result = data.routes;
		this.foundResult = data.routes.length > 0;

		var points = [];
		for (var route of data.routes)
			points = points.concat([route.fromStop, route.toStop]);
		points.push(this.address);
		this.fitPoints(points);
		this.drawBoundary();
	}

	clearFmbResult() {
		this.result = [];
		this.foundResult = false;
		this.map.highlight();
		this.map.removeLayer("trip-assign");
		this.map.removeLayer("trip-points");
		this.map.removeLayer("walkzone-boundary");
	}

	drawBoundary() {
		if (this.boundary) {
			var plg = this.map.polygon(this.boundary, { label: "School boundary", color: this.schoolColor, fillOpacity: 0.05 });
			this.map.addPolygon(plg, {
				name: "school-boundary",
				width: 3,
				label: true,
			});
		} else if (this.map.layerExists("school-boundary")) {
			this.map.removeLayer("school-boundary")
		}

		if (this.walkzone) {
			var plg = this.map.polygon(this.walkzone, { label: "Walkzone Boundary", color: this.schoolColor });
			this.map.addPolygon(plg, {
				name: "walkzone-boundary",
				width: 2,
				label: true,
				dash: [4, 1]
			});
		} else if (this.map.layerExists("walkzone-boundary")) {
			this.map.removeLayer("walkzone-boundary")
		}
	}

	async toggleTrip(route) {
		var trip = route.trip;
		trip.visible = true;

		var points = [route.fromStop, route.toStop];
		points.forEach(s => s.datatype = s.schoolId ? 'school' : 'stop');

		var stop = route.trip.session == "am" ? points[0] : points[1];
		this.map.addLine({
			points: [this.address, stop],
			name: "trip-assign",
			color: "red",
			hasArrow: false,
			before: "student-points"
		});

		this.map.addPoints({
			name: "trip-points",
			points: points,
			title: itm => itm.name,
			popupHandlers: this._routeHanders,
			before: "student-points",
		});
	}

	fitPoints(points) {
		var bounds = [ 1000, 1000, -1000, -1000 ];
		points.forEach(p => {
			if (bounds[0] > p.lng) bounds[0] = p.lng;
			if (bounds[1] > p.lat) bounds[1] = p.lat;
			if (bounds[2] < p.lng) bounds[2] = p.lng;
			if (bounds[3] < p.lat) bounds[3] = p.lat;
		});
		var points = [
			{ lng: bounds[0] - (bounds[2] - bounds[0]) / 4, lat: bounds[1] },
			{ lng: bounds[2], lat: bounds[3] },
		];
		this.map.fitBounds(points);
	}

	///////////////

	clearFmsResult() {
		this.enabled = false;
		this.map.highlight();
		this.map.removeLayer("school-location");
		this.map.removeLayer("school-boundary");
		this.map.removeLayer("school-walkzone");
	}

	findMySchool(data) {
		if (data.district || data.schools && data.schools.length) {
			this.enabled = true;

			data.schools.forEach(s => {
				if (s.website) {
					var protocol = s.website.match(/.*:\/\//);
					protocol = protocol ? protocol[0] : "";
					s.websiteName = s.website.replace(protocol, '');
					var pos = s.websiteName.indexOf('/');
					s.websiteName = protocol + (pos >= 0 ? s.websiteName.slice(0, pos) : s.websiteName);
				}
			})

			this.data.district = data.district;
			this.data.mySchools = data.schools.filter(s => s.isMine);
			this.data.notMySchools = data.schools.filter(s => !s.isMine);
			 
			this.setCenter(data.point);
			this.map.highlight(data.point);
		}
		if (this.data.mySchools && this.data.mySchools.length == 1)
			this.showSchoolDetails(this.data.mySchools[0]);
	}
	
	async showSchoolDetails(school) {
		school.datatype = "school";
		this.map.addPoints({
			points: [school],
			name: "school-location"
		});

		if (school.feature) {
			school.feature.properties.label = school.name;
			this.map.addPolygon(school.feature, {
				name: "school-boundary",
				width: 3,
				label: true,
				color: "#00f"
			});
			
			var points;
			if (this.map.isPolygon(school.feature.geometry.coordinates))
				points = school.feature.geometry.coordinates[0];
			else if (this.map.isMultiPolygon(school.feature.geometry.coordinates)) {
				var points = [];
				school.feature.geometry.coordinates.forEach(p => points = points.concat(p[0]));
			}

			points = points.map(p => {
				return { lng: p[0], lat: p[1] }
			});
			this.fitBounds(points);
		} else {
			this.map.removeLayer("school-boundary");
		}
		
		if (school.walkzone) {
			school.walkzone.properties.label = school.name + " Walkzone";
			this.map.addPolygon(school.walkzone, {
				name: "school-walkzone",
				width: 2,
				dash: [2, 1],
				label: true,
				color: "#f00"
			});
		}
	}
	
	setCenter(center) {
		this.map.mapObj.setZoom(11);
		var bbox = this.map.mapObj.getBounds();
		var barWidth = 400;
		var fullWidth = $(window).width();
		var percentage = barWidth / 2 / fullWidth;
		var midLng = center.lng - (bbox.getEast() - bbox.getWest()) * percentage;
		this.map.centerOnMap({ lng: midLng, lat: center.lat }, 11);
	}

	fitBounds(points) {
		if (!points || !points.length) return;

		var bounds = [200, 200, -200, -200];
		points.forEach(a => {
			var lng = Number(a.lng);
			var lat = Number(a.lat);
			if (!lng && !lat) return;
			if (lng < bounds[0]) bounds[0] = lng;
			if (lat < bounds[1]) bounds[1] = lat;
			if (lng > bounds[2]) bounds[2] = lng;
			if (lat > bounds[3]) bounds[3] = lat;
		});

		var barWidth = 400;
		var fullWidth = $(window).width();
		var percentage = barWidth / 2 / fullWidth;

		var dLng = (bounds[2] - bounds[0]) / 4 || 0.0125;
		var dLat = (bounds[3] - bounds[1]) / 4 || 0.0125;
		var delta = (bounds[2] - bounds[0] + 6 * dLng) * percentage;
		this.map.mapObj.fitBounds([
			[bounds[0] - dLng - delta, bounds[1] - dLat],
			[bounds[2] + dLng - delta, bounds[3] + dLat]
		]);
	}
}