import { BaseComponent } from "../shared/components/base.component";
import { DistrictService } from "../shared/services/district";

export class SchoolDetails extends BaseComponent {
    static inject() {
        return [ DistrictService ];
    }

	constructor(ds, ...rest) {
        super(...rest);
        this.ds = ds;

        this.data = {};
        this.enabled = false;
        this.subs = [];
		this.subs.push(this.ea.subscribe("located", data => this.show(data)));
    }

    detached() {
        this.subs.forEach(s => s.dispose());
    }

    enable(b) {
        this.enabled = b;
    }

    show(data) {
        if (data.district || data.schools && data.schools.length) {
            this.enable(true);

			data.schools.forEach(s => {
				if (s.website) {
					var protocol = s.website.match(/.*:\/\//);
					protocol = protocol ? protocol[0] : "";
					s.websiteName = s.website.replace(protocol, '');
					var pos = s.websiteName.indexOf('/');
					s.websiteName = protocol + (pos >= 0 ? s.websiteName.slice(0, pos) : s.websiteName);
				}
			})

			this.data.district = data.district;
            this.data.mySchools = data.schools.filter(s => s.isMine);
			this.data.notMySchools = data.schools.filter(s => !s.isMine);
			 
            this.map.clear();
			this.setCenter(data.point);
            this.map.highlight(data.point);

            if (data.district.feature) {
                data.district.feature.properties.label = data.district.name;
                this.map.addPolygon(data.district.feature, {
                    name: "district-boundary",
                    width: 4,
                    label: true,
                    dash: [ 4, 1 ]
                });
            }
		}
		if (this.data.mySchools && this.data.mySchools.length == 1)
			this.showSchoolDetails(this.data.mySchools[0]);
	}
	
    async showSchoolDetails(school) {
        school.datatype = "school";
        this.map.addPoints({
            points: [school],
            name: "school-location"
        });

        if (school.feature) {
			school.feature.properties.label = school.name;
			this.map.addPolygon(school.feature, {
				name: "school-boundary",
				width: 3,
                label: true,
                color: "#00f"
            });
            
            var points;
            if (this.map.isPolygon(school.feature.geometry.coordinates))
                points = school.feature.geometry.coordinates[0];
            else if (this.map.isMultiPolygon(school.feature.geometry.coordinates)) {
				var points = [];
				school.feature.geometry.coordinates.forEach(p => points = points.concat(p[0]));
			}

            points = points.map(p => {
                return { lng: p[0], lat: p[1] }
            });
            this.fitBounds(points);
		} else {
            this.map.removeLayer("school-boundary");
		}
		
		if (school.walkzone) {
			school.walkzone.properties.label = school.name + " Walkzone";
			this.map.addPolygon(school.walkzone, {
				name: "school-walkzone",
				width: 2,
				dash: [2, 1],
                label: true,
                color: "#f00"
            });
		}
	}
	
	setCenter(center) {
		this.map.mapObj.setZoom(11);
		var bbox = this.map.mapObj.getBounds();
		var barWidth = $("#address-search-box").width();
		var fullWidth = $(window).width();
		var percentage = barWidth / 2 / fullWidth;
		var midLng = center.lng - (bbox.getEast() - bbox.getWest()) * percentage;
		this.map.centerOnMap({ lng: midLng, lat: center.lat }, 11);
	}

	fitBounds(points) {
		if (!points || !points.length) return;

		var bounds = [200, 200, -200, -200];
		points.forEach(a => {
			var lng = Number(a.lng);
			var lat = Number(a.lat);
			if (!lng && !lat) return;
			if (lng < bounds[0]) bounds[0] = lng;
			if (lat < bounds[1]) bounds[1] = lat;
			if (lng > bounds[2]) bounds[2] = lng;
			if (lat > bounds[3]) bounds[3] = lat;
		});

		var barWidth = $("#address-search-box").width();
		var fullWidth = $(window).width();
		var percentage = barWidth / 2 / fullWidth;

		var dLng = (bounds[2] - bounds[0]) / 4 || 0.0125;
		var dLat = (bounds[3] - bounds[1]) / 4 || 0.0125;
		var delta = (bounds[2] - bounds[0] + 6 * dLng) * percentage;
		this.map.mapObj.fitBounds([
			[bounds[0] - dLng - delta, bounds[1] - dLat],
			[bounds[2] + dLng - delta, bounds[3] + dLat]
		]);
	}
}