import {Api} from './api';

export class AddressService {
	static inject() {
		return [Api];
	}

	constructor(api) {
		this.api = api;
	}

    /**
     * Geocode
     * @param {object} address 
     */
	async geocode(address) {
		var r = await this.api.post("/api/address/geocode", address)
		address.lat = r.lat;
		address.lng = r.lng;
		return r;
	}

	async findPlace(latLng) {
		var r = await this.api.post("/api/address/place", latLng);
		return r;
	}

	async validateAddress(address) {
		try {
			var r = await this.api.post("/api/address/validate", { address: address });
			return r;
		} catch(e) {
			if (e.errcode != "FF0FC717")
				throw e;
		}
	}

	async suggestAddress(address, point) {
		return await this.api.get(`/api/address/suggest?address=${encodeURI(address)}&coord=${point.lng},${point.lat}`);
	}

	async getMyIpLocation() {
		// var r = await this.api.get("https://freegeoip.net/json/");
		return null;
	}
}